import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  beginnerModeScoreDecrease,
  beginnerModeScoreIncrease,
} from "../slices/userSlice";
import { IncreaseIndex } from "../slices/indexSlice";
import {
  DecreaseTime,
  IncreaseTimeForProgress,
  ResetTime,
  ResetTimeForProgress,
  SetProgress,
} from "../slices/timeSlice";

import { useNavigate } from "react-router-dom";
import { beginnerModeOpponentScore } from "../slices/opponentSlice";
import axios from "axios";
import { baseURL, questionsURL, saveScore } from "../api";
import { toast } from "react-toastify";
import { storeQuestions } from "../slices/questionsSlice";
import Loader from "../components/Loader";
import {
  increaseCorrectAnswer,
  increaseWrongAnswer,
} from "../slices/answersSlice";
import { useParams } from "react-router-dom";
import Audio from "../components/Audio";

const GamePlayDashboard = () => {
  const { category, categoryId, mode } = useParams();
  const question = useSelector((state) => state.questions);
  // console.log(question);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const { opponent, opponentScore } = useSelector((state) => state.opponent);

  const { user } = useSelector((state) => state.user);
  const { index } = useSelector((state) => state.index);

  const { timeClock, progress } = useSelector((state) => state.time);
  const [time, setTime] = useState(timeClock);

  const [correct, setCorrect] = useState(null);
  const [wrong, setWrong] = useState(null);

  const [loading, setLoading] = useState(false);
  const { score } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    //if check if no questions in store then fetch questions and store it in store
    // fetchQuestionsFromBackend();
    if (!loading) {
      setTimeout(() => {
        setCorrect(null);
        setWrong(null);
        setTime(time - 1);
        dispatch(DecreaseTime());
        dispatch(IncreaseTimeForProgress());
        dispatch(SetProgress());
      }, 1000);
    }
  }, [time, loading]);

  useEffect(() => {
    async function fetchDataFromLocalStorage() {
      console.log("fetch data from backend");

      try {
        setLoading(true);

        const res = await axios.get(
          `${baseURL}${questionsURL}?category=${category}&limit=10`,
          {
            headers: {
              Authorization: `${user.response}`,
            },
          }
        );
        console.log(res.data);
        dispatch(storeQuestions(res.data));
        setQuestions(res.data);
        setLoading(false);

        // console.log(res);
      } catch (err) {
        toast.error(err || err?.data?.message || err?.message);
        setLoading(false);
      }
    }

    if (
      !question.questions ||
      question.questions == "" ||
      question.questions.length < 0
    ) {
      setLoading(true);
      fetchDataFromLocalStorage();
    }
  }, []);

  if (index === questions?.length && questions.length > 0 && !loading) {
    // api send data to backend
    const sendScoreToBackend = async () => {
      try {
        await axios.get(
          `${baseURL}${saveScore}?score=${score}&categoryId=${categoryId}`,
          {
            headers: {
              Authorization: `${user.response}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
    navigate(`/game/${category}/${categoryId}/${mode}/results`);
  }

  if (timeClock < 0) {
    console.log("api send");
    // api send data to backend of results..
    const sendScoreToBackend = async () => {
      try {
        await axios.get(
          `${baseURL}${saveScore}?score=${score}&categoryId=${categoryId}`,
          {
            headers: {
              Authorization: `${user.response}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
    navigate(`/game/${category}/${categoryId}/${mode}/results`);
  }

  const checkAnswerAndNextQuestion = (option) => {
    console.log(option);
    if (
      questions[index].correct == option.index ||
      questions[index].correct == option.option ||
      questions[index].correctAnswer == option.name
    ) {
      // console.log("correct");
      setCorrect(option.index);
      dispatch(IncreaseIndex());

      //We will increase the score
      dispatch(beginnerModeScoreIncrease());
      dispatch(beginnerModeOpponentScore());
      dispatch(increaseCorrectAnswer());
      setWrong(null);
      // setCorrect(false);
    } else {
      // console.log("wrong");
      setWrong(option.index);
      dispatch(IncreaseIndex());
      dispatch(beginnerModeOpponentScore());
      dispatch(increaseWrongAnswer());
      setCorrect(null);
      // setWrong(false);
    }
  };

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Audio />
          <Box
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAY1BMVEUiLTqzusC2vcO5wMa8w8keKjccKDYYJDMRIC8VIjEZJjQgKzkNHS2wt72Ij5YAEiWRmJ8HGSpfZ3Ckq7J2fYWBiJCepaxJUVsxO0Y3QEuqsbdCS1ZYYGltdX08RVAqNEFQWWLPmQz1AAAEEklEQVR4nO2a23KjMAyGAWNjczDmDAFC3v8pFxLaJt2sYxGLdmb5r9orvpFkSZHkOIcOHTp06NChQ4f+S9GAcyEE5wH9ke8zEcfnvKyklFWZn7NY7M0hsr6sie8Tb9HyR12es2BPgiiv50+795r/r3O2FwVlw4k8AqwYpBj2iQze1v4TgJVCTQIfIc69Zzb4pHCHGJmABiXRECwiI7IlYvkKYYYoM0yE1ABhhqhSPAQx/jMaH+TnaO4IGl003svrQyyIwpihRmLIKpNguIlUKHHJOmOCRS1GwhTS1BNXb1QIYUknc09cIRz7huAvE+SjyMitMzg1xBXL02C2CegZRDArOdt2hmmK/JKf225oMtCrWESk7SKeFkAE1y1sV64oATOcLPsiOsMZks5uULIejOC6/S9gaOxmCNZsYbBrB/oL7EA7eEy6thMlO4ERkimyy7AhR51st1KZguZqT9nOk9D2YWkpbdcLdgHXrIvt3pq20KA82e9qQ2BAIPRRjshhTQxKPxkB7eBYzg6LOOz3hcT42ct6EIPlYrEqBUSl/QR1E4XUbxwzmA2CbrLfU38omkwLeDKhjSnDi+Es6II4rzUbgxCJOpgLa4PZIEKWvheNXkKQGmHy8AjxajDmFROuGa4QWkuQHRBmiFD++3X4ku2zzokvyXNTkAR9aP8p0Ur376jwXNXusLz4EI0bRR72GJ5HVMN3CIV7irQta/dzp+bW5ZTuv2CkIpiasZJKVmMzhQKhbTLCYPwqwX4I4NChX6coYmEYLApDFu2eHJjgoTN1zWXIFw2XppuccH6j+3yesjjNzmOliuR2dEBuJwhJoeZcxdMYu3BSkU1DVRCfPFl6X5EKObQp4jlGEHajSr4fPnwH8ZI67wKUxpoKNqjEN/m55/mJykPrxqBZW5oBfGJUndVCyuJeaT3w3Cuqj609FN7JBEpwpUhkZ2cYE2Yj2AYfIm7JLURnNpygk8kHiiTP3gwLFsnNRrhpDgvnrUGlOBfvGGE1RdG/ERXp9kh4NMW4dTJEY8DBg15ExpuCgjrGox8TCGdLqhDKHsIMoeBXbFFmMO0AQdRgdwiLjlghFDBb8RK65jeAqEBPNM5tW+EKkQMmAwx8cGEmrzd/HNz4GAvIUBh7AyMYbiKlIQRsSQCEaMzqFwdeAEHk1UbTonDAQ5ghjC51UqSAXBlOBiU0REkNXyIGhggQo2GRwWkl67He5Yf8l2sm833RVnmv9kzwtTZcrxbhuA/zJm/QR2WG7orFGfpVE8d3hesm2qKx6RQLLm0JD3L8cJidMepSBK92YZA6Z2TISXJlKHRBmW64BtsiHUO8hxlmQ2hWDdTBzw6LfM11J22xC9bKoLnmo91ODJoEgV+4VwbNCdnB8KMMfwBRxT2y5SYoeAAAAABJRU5ErkJggg=="
                // src="https://demo.quizpro.mobi//uploads/user/2/avatar.png"
                alt="user"
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  You
                </Typography>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {score}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "white", position: "absolute" }}
              >
                {timeClock}
              </Typography>
              <CircularProgress
                sx={{ color: "white" }}
                variant="determinate"
                value={progress}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {opponent.name}
                </Typography>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {opponentScore}
                </Typography>
              </Box>
              <Avatar src={opponent.image} alt={opponent.name} />
            </Box>
          </Box>

          <Box my={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {index + 1} of {questions?.length}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fd7e14",
              color: "white",
              //   gap: "2rem",
              flexDirection: "column",
              // backgroundColor: "#e9f1fa",
              borderRadius: "20px",
            }}
          >
            {questions[index]?.image && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: "100%", height: "300px" }}
                  src={questions[index]?.image}
                  alt="random"
                />
              </Box>
            )}
            {questions[index]?.video && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  controls
                  loop
                  style={{ width: "100%", height: "300px" }}
                  autoPlay
                  muted
                >
                  <source
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={questions[index]?.video}
                    type="video/mp4"
                  />
                </video>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                borderRadius: "1rem",
                backgroundColor: "#fd7e14",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
                padding: "2rem 1rem",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {/* {questions.length>0 && !loading ? 
                  questions[index]?.question : "No Questions Found Sorry"
                } */}
                {questions[index]?.question}
              </Typography>
            </Box>
          </Box>
          <Box>
            <List
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {questions[index]?.optionA && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "A",
                      index: "1",
                      name: questions[index]?.optionA,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "1" ? "green" : wrong == "1" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        1. {questions[index]?.optionA}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionB && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "B",
                      index: "2",
                      name: questions[index]?.optionB,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "2" ? "green" : wrong == "2" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        2. {questions[index]?.optionB}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionC && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "C",
                      index: "3",
                      name: questions[index]?.optionC,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "3" ? "green" : wrong == "3" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        3. {questions[index]?.optionC}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionD && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "D",
                      index: "4",
                      name: questions[index]?.optionD,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "4" ? "green" : wrong == "4" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        4. {questions[index]?.optionD}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default GamePlayDashboard;
