import { Box, Modal, TextField } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideSearchBox } from "../slices/searchSlice";
import SearchIcon from "@mui/icons-material/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0 solid #8c52ff",
  boxShadow: 24,
  p: 4,
};

const SearchBox = () => {
  const { searchBox } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideSearchBox());
  };
  const handleSearch = () => {
    dispatch(hideSearchBox());
  };
  return (
    <Modal
      open={searchBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <TextField id="search" label="Search Quizes" variant="standard" />
          <Box
            sx={{
              marginBottom: "-6%",
            }}
          >
            <SearchIcon
              onClick={handleSearch}
              color="primary"
              sx={{ fontSize: "1.8rem", cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchBox;
