import React from "react";
import BottomNavbar from "../components/BottomNavbar";
// import Header from "../components/Header";
// import SearchBox from "../components/SearchBox";
// import Heading from "../components/Heading";
import Layout from "../components/Layout";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// import PersonIcon from "@mui/icons-material/Person";

const Signup = () => {
  const submitHandler = (e) => {
    e.preventDefault();
  };
  return (
    <Layout>
      {/* <Header />
      <SearchBox />
      <Heading
        title="Sign Up"
        icon={<PersonIcon sx={{ position: "relative", top: "11%" }} />}
      /> */}

      <Box
        mt={8}
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://demo.quizpro.mobi//theme/images/logo-light_quizypro.png"
          alt="logo"
          loading="lazy"
          width="120px"
        />
        <Typography
          sx={{ color: "#fff", fontWeight: "500", fontSize: "1rem" }}
          variant="body1"
        >
          Create an Account To Play the Quiz
        </Typography>
      </Box>

      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <form onSubmit={submitHandler}>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="email"
                sx={{ width: "100%" }}
                label="Email"
                variant="outlined"
              />
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="password"
                sx={{ width: "100%" }}
                label="Password"
                type="password"
                variant="outlined"
              />
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#FE9E44",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Sign up
              </Button>
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1">
                Already have an Account , <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
      <BottomNavbar value={3} label="Sign up" />
    </Layout>
  );
};

export default Signup;
