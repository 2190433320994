import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header2 from "../components/Header2";
import GameDetailsImage from "../components/GameDetailsImage";
import { Box, Button, IconButton, Typography } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showLoginModalBox } from "../slices/loginModalSlice";
import { showDifficultyModalBox } from "../slices/difficultyLevelsSlice";
import ModalForLoginToPlay from "../components/ModalForLoginToPlay";
import ModalForDifficultyLevels from "../components/ModalForDifficultyLevels";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL, getGameDescription } from "../api";
import Loader from "../components/Loader";

const GameDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { category, categoryId } = useParams();
  // console.log(category);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const checkTokenAndNavigate = () => {
    const token = user?.response;
    if (token) {
      //show difficulty levels modal
      dispatch(showDifficultyModalBox());
    } else {
      //Open the modal that says login first to play
      dispatch(showLoginModalBox());
    }
  };

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${baseURL}${getGameDescription}?categoryId=${categoryId}`
        );
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header2 title={data.categoryName} />
          <GameDetailsImage image={data.categoryImage}/>

          <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "1rem",
              }}
            >
              <Box sx={{ margin: "0 2rem", paddingTop: "1rem" }}>
                <Typography variant="h5">{data.categoryName} Quiz</Typography>
              </Box>

              <Box
                sx={{
                  margin: "0 auto",
                  padding: "1rem 1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  backgroundColor: "#ffebd9",
                  borderRadius: "50rem",
                }}
              >
                <Box>
                  <IconButton sx={{ backgroundColor: "#FF8FA3" }}>
                    <QuestionMarkIcon sx={{ color: "white" }} />
                  </IconButton>{" "}
                  {data.question} Questions
                </Box>
                <Box>
                  <IconButton sx={{ backgroundColor: "#FF8FA3" }}>
                    <StarRateIcon sx={{ color: "white" }} />
                  </IconButton>{" "}
                  {data.points} Points
                </Box>
              </Box>
              <Box sx={{ margin: "0 2rem" }}>
                <Typography variant="h5">Description</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#6c757d", padding: "1rem 0" }}
                >
                  {data.description}
                </Typography>
              </Box>
              <Box sx={{ margin: "0 5% 10%", width: "90%" }}>
                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "#FE9E44",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  variant="contained"
                  onClick={checkTokenAndNavigate}
                >
                  Play
                </Button>
              </Box>
            </Box>
          </Box>
          <ModalForLoginToPlay />
          <ModalForDifficultyLevels
            category={data.categoryName}
            categoryId={data.categoryId}
          />
        </>
      )}
    </Layout>
  );
};

export default GameDetails;
