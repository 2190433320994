import { Box } from '@mui/material'
import React from 'react'

const GameDetailsImage = (props) => {
    const {image}=props;
  return (
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={image} alt="random" width="30%" height="140px" style={{objectFit:"cover",borderRadius:"8px"}}/>
    </Box>
  )
}

export default GameDetailsImage