import React from "react";
import { Box, Typography, Grid, Avatar, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import classes from "./PopularQuiz2.module.css";

const FeaturedQuiz = ({ quizes, loading }) => {
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <Box mb={4} sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
      <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Featured Quiz
        </Typography>
      </Box>
      <Box sx={{ margin: "0 2rem", padding: "0 0" }}>
        <Grid container spacing={1}>
          {loading ? (
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              mb={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Loader />
            </Grid>
          ) : (
            <>
              {quizes.map((dataItem) => {
                return (
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    lg={2}
                    xl={2}
                    mb={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    key={dataItem.id}
                  >
                  <Link to={`/game/details/${dataItem.categoryName}/${dataItem.categoryId}`}>

                    <Avatar
                      sx={{ maxWidth: "100%", borderRadius: "8px" }}
                      alt="random"
                      variant="square"
                      src={dataItem.categoryImage}
                    />
                    </Link>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.875rem",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        fontWeight: "600",
                      }}
                      className={classes.text}
                    >
                      {dataItem.categoryName}
                    </Typography>
                  </Grid>
                );
              })}
            </>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            mb={2}
            // sx={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            <Button
              sx={{
                width: "100%",
                backgroundColor: "#FE9E44",
                color: "#fff",
                fontWeight: "bold",
              }}
              variant="contained"
              onClick={() => handleNavigate("/all-quizes")}
            >
              All Quizes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FeaturedQuiz;
