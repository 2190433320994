import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
      user: null,
      score: 0,
    };

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserCredentials: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    resetScore: (state, action) => {
      state.score = 0;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    removeUserCredentials: (state, action) => {
      state.user = null;
      localStorage.removeItem("user");
      return state;
    },
    beginnerModeScoreIncrease: (state, action) => {
      state.score = state.score + 50;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    beginnerModeScoreDecrease: (state, action) => {
      state.score = state.score - 50;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
  },
});

export const {
  setUserCredentials,
  removeUserCredentials,
  resetScore,
  beginnerModeScoreIncrease,
  beginnerModeScoreDecrease,
} = UserSlice.actions;
export default UserSlice;
