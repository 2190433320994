import React, { useEffect, useState } from "react";
import BottomNavbar from "../components/BottomNavbar";
import Layout from "../components/Layout";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL, loginURL } from "../api";
import { setUserCredentials } from "../slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {user}=useSelector(state=>state.user);
  
  useEffect(()=>{
    if(user){
      navigate("/account");
    }
  },[])

  // const user = useSelector((state) => state.user);
  // console.log(user);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!username || !password) {
        toast.error("Email or password fields cannot be empty");
        return;
      }
      const data = { username, password };
      setLoading(true);
      const res = await axios.post(`${baseURL}${loginURL}`, data);
      // console.log(res);
      dispatch(setUserCredentials(res.data));
      setLoading(false);
      navigate("/");
      toast.success("Logged in successfully");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.response?.data?.response || err?.error || err?.response);
    }
  };
  return (
    <Layout>
      <Box
        mt={8}
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://demo.quizpro.mobi//theme/images/logo-light_quizypro.png"
          alt="logo"
          loading="lazy"
          width="120px"
        />
        <Typography
          sx={{ color: "#fff", fontWeight: "500", fontSize: "1rem" }}
          variant="body1"
        >
          Sign In To Play the Quiz
        </Typography>
      </Box>
      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <form onSubmit={submitHandler}>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="username"
                type="number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{ width: "100%" }}
                label="Username"
                variant="outlined"
              />
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <TextField
                id="password"
                sx={{ width: "100%" }}
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                type="submit"
                sx={{
                  width: "100%",
                  backgroundColor: "#FE9E44",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Login
              </Button>
            </Box>
            <Box
              my={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1">
                Don't have an Account , <Link to="/signup">Sign Up</Link>
              </Typography>
            </Box>
          </form>

          {loading && (
            <Loader />
          )}
        </Box>
      </Box>
      <BottomNavbar value={3} label="Login" />
    </Layout>
  );
};

export default Login;
