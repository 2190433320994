import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("questions")?
JSON.parse(localStorage.getItem("questions")):
{
    questions:null
}

const QuestionsSlice=createSlice({
    name:"questions",
    initialState,
    reducers:{
        storeQuestions:(state,action)=>{
            state.questions=action.payload;
            localStorage.setItem("questions",JSON.stringify(state));
            return state;
        },
        resetQuestions:(state,action)=>{
            state.questions=null;
            localStorage.removeItem("questions");
            return state;
        }
    }
})

export const {storeQuestions,resetQuestions}=QuestionsSlice.actions;
export default QuestionsSlice;