import React from "react";
import { Box} from "@mui/material";
import classes from "./Heading.module.css";

const Heading = (props) => {
  return (
    <Box className={classes.page_title}>
      <Box
        className={classes.page_title_circle}
      >
        {props.icon}
      </Box>
       <span>{props.title}</span>
    </Box>
  );
};

export default Heading;
