const baseURL="http://5.189.166.187:5252/";
export {baseURL};

const loginURL="signin/token";
export {loginURL};

const questionsURL="category";
export {questionsURL};

const quizURL="showCategory";
export {quizURL};

const getGameDescription="getDescription";
export {getGameDescription};

const saveScore="saveScore";
export {saveScore};