import React from 'react'
import classes from "./Layout.module.css";

const Layout = ({children}) => {
  return (
    <main className={classes.box_container}>
        <main className={classes.box_sub_container}>
            {children}
        </main>
    </main>
  )
}

export default Layout