import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("answers")?JSON.parse(localStorage.getItem("answers")):
{
    correctAnswer:0,
    wrongAnswer:0
};

const AnswersSlice=createSlice({
    name:"answers",
    initialState,
    reducers:{
        increaseCorrectAnswer:(state,action)=>{
            state.correctAnswer=state.correctAnswer+1;
            localStorage.setItem("answers",JSON.stringify(state));
            return state;
        },
        increaseWrongAnswer:(state,action)=>{
            state.wrongAnswer=state.wrongAnswer+1;
            localStorage.setItem("answers",JSON.stringify(state));
            return state;
        },
       resetCorrectAnswer:(state,action)=>{
        state.correctAnswer=0;
        localStorage.setItem("answers",JSON.stringify(state));
        return state;
       },
       resetWrongAnswer:(state,action)=>{
        state.wrongAnswer=0;
        localStorage.setItem("answers",JSON.stringify(state));
        return state;
       }
    }
}) 

export const {increaseCorrectAnswer,increaseWrongAnswer,resetCorrectAnswer,resetWrongAnswer}=AnswersSlice.actions;
export default AnswersSlice;