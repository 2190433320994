import React from "react";
import { Box, Modal, Button, Typography, Backdrop, Fade } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideLoginModalBox } from "../slices/loginModalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const ModalForLoginToPlay = () => {
  const navigate = useNavigate();
  const { loginModalBox } = useSelector((state) => state.loginModal);
  // console.log(loginModalBox);

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0 solid #8c52ff",
    boxShadow: 24,
    // p: 4,
  };
  const handleClose = () => {
    dispatch(hideLoginModalBox());
  };
  const handleNavigate=(route)=>{
    navigate(route);
    handleClose();
  }
  return (
    <Modal
      open={loginModalBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={loginModalBox}>
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#3EA9F5",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem 0",
            }}
          >
            <img
              src="https://demo.quizpro.mobi//theme/images/logo_quizypro.png"
              width="80"
              alt="Logo"
              style={{ marginLeft: "1rem" }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{ cursor: "pointer", color: "white", marginRight: "1rem" }}
            />
          </Box>
          <Box>
            <Typography
              sx={{ textAlign: "left", padding: "1rem 1rem" }}
              variant="h6"
            >
              Please Login To Play
            </Typography>
          </Box>
          <Box
            mb={2}
            mr={1}
            sx={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" onClick={() => handleNavigate("/login")}>
              Login
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalForLoginToPlay;
