import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Guest from "../components/Guest";
// import PopularQuiz from "../components/PopularQuiz";
import PopularQuiz2 from "../components/PopularQuiz2";
import FeaturedQuiz from "../components/FeaturedQuiz";
// import Carousel from "../components/Carousel";
import BottomNavbar from "../components/BottomNavbar";
import SearchBox from "../components/SearchBox";
import axios from "axios";
import { baseURL, quizURL } from "../api";

const Home = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${baseURL}${quizURL}`);
        console.log(res);
        setQuizes(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <Layout>
      <>
        <Header />
        <SearchBox />
        <Guest />
        {/* <PopularQuiz /> */}
        <PopularQuiz2 quizes={quizes} loading={loading}/>
        <FeaturedQuiz quizes={quizes} loading={loading}/>
        {/* <Carousel /> */}
        <BottomNavbar value={0} />
      </>
    </Layout>
  );
};

export default Home;
