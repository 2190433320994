import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const Header2 = (props) => {
  const { title } = props;
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    // <Box mt={4} sx={{position:"relative",display:"flex",justifyContent:"flex-start"}}>
    //   <Box>
    //     <IconButton onClick={()=>handleNavigate("/")}>
    //       <KeyboardBackspaceIcon sx={{color:"white",fontSize:"2rem"}} />
    //     </IconButton>
    //   </Box>
    //   <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",left:"35%",top:"18%"}}>
    //     <Typography variant="h5" sx={{color:"white"}}>{title}</Typography>
    //   </Box>
    // </Box>
    <Box
      mt={4}
      ml={0}
      mr={0}
      sx={{ position: "relative", textAlign: "center",color:"white",fontWeight:"bold" }}
    >
      <KeyboardBackspaceIcon
        onClick={() => handleNavigate("/")}
        sx={{
          color: "#fff",
          display: "inline-block",
          position: "absolute",
          marginRight: "0.5rem",
          left: "0.75rem",
          cursor: "pointer",
          fontSize: "2rem",
        }}
      />
      <Typography variant="h6" sx={{marginTop:"0.5%"}}>{title}</Typography>
    </Box>
  );
};

export default Header2;
