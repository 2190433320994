import React from "react";
import {
  Box,
  Modal,
  Slide,
  Backdrop,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideDifficultyModalBox } from "../slices/difficultyLevelsSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";

const ModalForDifficultyLevels = ({category,categoryId}) => {
  const navigate = useNavigate();
  const { difficultyModalBox } = useSelector((state) => state.difficultyModal);
  // console.log(loginModalBox);

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "0%",
    left: "0%",
    transform: "translate(0%, 0%)",
    width: "100vw",
    height: "100vh",
    bgcolor: "background.paper",
    border: "0 solid #8c52ff",
    boxShadow: 24,
    // p: 4,
  };
  const handleClose = () => {
    dispatch(hideDifficultyModalBox());
  };
  const handleNavigate = (route) => {
    dispatch(hideDifficultyModalBox());
    navigate(route);
  };
  return (
    <Modal
      open={difficultyModalBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Slide direction="up" in={difficultyModalBox} mountOnEnter unmountOnExit>
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#3EA9F5",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem 0",
            }}
          >
            <img
              src="https://demo.quizpro.mobi//theme/images/logo_quizypro.png"
              width="80"
              alt="Logo"
              style={{ marginLeft: "1rem" }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{ cursor: "pointer", color: "white", marginRight: "1rem" }}
            />
          </Box>
          <Box mt={4} sx={{ width: "100%" }}>
            <Typography
              variant="h5"
              sx={{
                letterSpacing: "0.8px",
                padding: "1rem 0",
                textAlign: "center",
              }}
            >
              Difficulty Levels
            </Typography>
          </Box>
          <Layout>
            <List
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <ListItem
                onClick={() => handleNavigate(`/game/${category}/${categoryId}/search/opponent/beginner`)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#fec590",
                  borderRadius: "8rem",
                }}
                secondaryAction={
                  <Box
                    // className={classes.points_box}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {/* <MilitaryTechIcon color="primary" /> */}

                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0 1rem",
                        color: "#c15e01",
                        backgroundColor: "white",
                        borderRadius: "8rem",
                      }}
                    >
                      <span>+50 points</span>
                    </Typography>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "white" }}
                    >
                      Beginner
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                onClick={() => handleNavigate("/game/1/search/opponent/intermediate")}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#FE9E44",
                  borderRadius: "8rem",
                }}
                secondaryAction={
                  <Box
                    // className={classes.points_box}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {/* <MilitaryTechIcon color="primary" /> */}

                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0 1rem",
                        color: "#c15e01",
                        backgroundColor: "white",
                        borderRadius: "8rem",
                      }}
                    >
                      <span>+70 points</span>
                    </Typography>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "white" }}
                    >
                      Intermediate
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem
                onClick={() => handleNavigate("/game/1/search/opponent/advance")}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#c15e01;",
                  borderRadius: "8rem",
                }}
                secondaryAction={
                  <Box
                    // className={classes.points_box}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {/* <MilitaryTechIcon color="primary" /> */}

                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0 1rem",
                        color: "#c15e01",
                        backgroundColor: "white",
                        borderRadius: "8rem",
                      }}
                    >
                      <span>+100 points</span>
                    </Typography>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "white" }}
                    >
                      Advance
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Layout>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalForDifficultyLevels;
