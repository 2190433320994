import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./Header.module.css";
import { useSelector, useDispatch } from "react-redux";
import { showSearchBox } from "../slices/searchSlice";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const Header = () => {
  const navigate = useNavigate();

  const { searchBox } = useSelector((state) => state.search);
  // console.log(searchBox);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(showSearchBox());
  };
  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <Box mt={4}>
      <div className={classes.header}>
        <img
          className={classes.logo}
          onClick={handleNavigate}
          src="https://demo.quizpro.mobi//theme/images/logo_quizypro.png"
          alt="Logo"
        />
        {/* <h2
          style={{ cursor: "pointer" }}
          onClick={handleNavigate}
          className={classes.brand_name}
        >
          Quiz
          <span
            style={{ cursor: "pointer" }}
            onClick={handleNavigate}
            className={classes.brand_name_bold}
          >
            PRO
          </span>
        </h2> */}
        <div style={{ position: "relative" }} className={classes.search_icon}>
          <SearchIcon
            onClick={handleClick}
            style={{
              color: "#e9f1fa",
              position: "absolute",
              top: "8%",
              right: "9%",
              fontSize: "1.8rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </Box>
  );
};

export default Header;
