import React from "react";
import BottomNavbar from "../components/BottomNavbar";
import Header from "../components/Header";
import SearchBox from "../components/SearchBox";
import Heading from "../components/Heading";
import Layout from "../components/Layout";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Badge,
  Avatar,
  ListItemText,
} from "@mui/material";
import classes from "./Leaderboard.module.css";

const LeaderBoard = () => {
  return (
    <Layout>
      <Header />
      <SearchBox />
      <Heading
        title="Leaderboard"
        icon={<LeaderboardIcon sx={{ position: "relative", top: "11%" }} />}
      />
      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 0", padding: "2rem 0" }}>
          <List>
            <ListItem
              secondaryAction={
                <Box
                  className={classes.points_box}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {/* <MilitaryTechIcon color="primary" /> */}
                  <span className={classes.coin}></span>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>19900</span>
                  </Typography>
                </Box>
              }
            >
              <ListItemAvatar>
                <Badge
                  badgeContent={1}
                  color="success"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar src="/images/broken-image.jpg" />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary="John"
                secondary={
                  <>
                    <Typography
                      //   sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}
                      variant="body1"
                    >
                      Score <span style={{ fontWeight: "bold" }}>18900,</span>
                      <AccessTimeIcon
                        sx={{ marginBottom: "-0.3rem", fontSize: "1.2rem" }}
                      />
                      <span style={{ fontWeight: "bold" }}> 188.3</span>{" "}
                    </Typography>
                  </>
                }
              />
            </ListItem>

            <Divider />

            <ListItem
              secondaryAction={
                <Box
                  className={classes.points_box}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {/* <MilitaryTechIcon color="primary" /> */}
                  <span className={classes.coin}></span>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>16900</span>
                  </Typography>
                </Box>
              }
            >
              <ListItemAvatar>
                <Badge
                  badgeContent={2}
                  color="success"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar src="/images/broken-image.jpg" />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary="Mike"
                secondary={
                  <>
                    <Typography
                      //   sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}
                      variant="body1"
                    >
                      Score <span style={{ fontWeight: "bold" }}>16900,</span>
                      <AccessTimeIcon
                        sx={{ marginBottom: "-0.3rem", fontSize: "1.2rem" }}
                      />
                      <span style={{ fontWeight: "bold" }}> 168.3</span>{" "}
                    </Typography>
                  </>
                }
              />
            </ListItem>

            <Divider />

            <ListItem
              secondaryAction={
                <Box
                  className={classes.points_box}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {/* <MilitaryTechIcon color="primary" /> */}
                  <span className={classes.coin}></span>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>14300</span>
                  </Typography>
                </Box>
              }
            >
              <ListItemAvatar>
                <Badge
                  badgeContent={3}
                  color="success"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar src="/images/broken-image.jpg" />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary="James"
                secondary={
                  <>
                    <Typography
                      //   sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}
                      variant="body1"
                    >
                      Score <span style={{ fontWeight: "bold" }}>14900,</span>
                      <AccessTimeIcon
                        sx={{ marginBottom: "-0.3rem", fontSize: "1.2rem" }}
                      />
                      <span style={{ fontWeight: "bold" }}> 148.3</span>{" "}
                    </Typography>
                  </>
                }
              />
            </ListItem>

            <Divider />
          </List>
        </Box>
      </Box>
      <BottomNavbar value={2} />
    </Layout>
  );
};

export default LeaderBoard;
