import React from "react";
import { Box, Avatar, Typography, Button } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const Guest = () => {
  return (
    <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: {
            xs: "0 1rem",
            sm: "0 1.3rem",
            lg: "0 2rem",
          },
          padding: "2rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src="/images/broken-image.jpg"
          />
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              fontWeight: "bold"
            }}
          >
            Guest
          </Typography>
        </Box>
        <Button
        
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FE9E44",
            borderRadius: "50rem",
            color: "#fff",
            padding: "6px 9px",
          }}
          startIcon={<EmojiEventsIcon />}
        >
          20 Points
        </Button>
      </Box>
    </Box>
  );
};

export default Guest;
