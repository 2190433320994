import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import AllQuizes from "./routes/AllQuizes";
import LeaderBoard from "./routes/Leaderboard";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import GameDetails from "./routes/GameDetails";
import FindingOpponent from "./routes/FindingOpponent";
import GamePlayDashboard from "./routes/GamePlayDashboard";
import GameResults from "./routes/GameResults";
import Account from "./routes/Account";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/all-quizes",
      element: <AllQuizes />,
    },
    {
      path: "/leaderboard",
      element: <LeaderBoard />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path:"/account",
      element:<Account />
    },
    {
      path: "/game/details/:category/:categoryId",
      element: <GameDetails />,
    },
    {
      path: "/game/:category/:categoryId/search/opponent/:mode",
      element: <FindingOpponent />,
    },
    {
      path: "/game/:category/:categoryId/play/:mode",
      element: <GamePlayDashboard />,
    },
    {
      path: "/game/:category/:categoryId/:mode/results",
      element: <GameResults />,
    },
    
  ]);
  return <RouterProvider router={router} />;
};

export default App;
